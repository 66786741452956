<template>
<div  class="draggable-con">
  <draggable 
  v-model="myArray" 
  group="people" 
  @start="drag=true" 
  @end="drag=false" 
  item-key="id">
  <template #item="{element}">
    <div class="student-element">{{element.name}}</div>
   </template>
</draggable>
</div>
</template>
<script>
import draggable from 'vuedraggable'
export default {
    components: {
        draggable,
    },
    data() {
      return {
        drag: false,
        myArray: [{id:"1",name:"çakmak ahmet"},{id:"2",name:"CSS"},{id:"3",name:"sdasd"}],
      }
    },

}
</script>
<style scoped>
    .student-element{
      min-width:100px;
      max-width:max-content;
      height:40px;
      padding:0 10px;
      color:#fff;
      background-color:#333333;
      border-radius:12px;
      margin:10px;
      display:flex;
      align-items:center;
      justify-content:center;
      align-items:center;
      cursor:move;
    }
    .draggable-con div{
      flex-wrap:wrap; 
      display:flex;
    }
</style>